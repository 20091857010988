export const IS_LOCAL = process.env.IS_LOCAL === 'true';
export const NODE_ENV = process.env.NODE_ENV || 'development'; // ONLY "production" || "development"
export const APP_ENV = process.env.APP_ENV || 'development'; // staging, production, dev
export const IS_PREVIEW_MODE = process.env.IS_PREVIEW_MODE;
export const IS_PROD = APP_ENV === 'production';
export const PORT = process.env.PORT || 4000;
export const GRAPHQL_URL = process.env.GRAPHQL_URL || 'http://api-aggregate.test.eol.inbcu.com';
export const PUBLIC_GRAPHQL_URL =
	process.env.PUBLIC_GRAPHQL_URL || 'https://api-aggregate.test.eonline.com';
export const LOG_LEVEL = process.env.LOG_LEVEL;
export const ADOBE_LAUNCH = process.env.ADOBE_LAUNCH;
export const APP_HOSTNAME = process.env.APP_HOSTNAME || 'www.dev.eonline.com';
export const POLLS_POST_API = process.env.POLLS_POST_API;
export const NEWSLETTER_POST_API = process.env.NEWSLETTER_POST_API;
export const HOSTNAME_ALIAS = process.env.HOSTNAME_ALIAS;
export const CHECKPOINT_API =
	process.env.CHECKPOINT_API || 'https://checkpoint-staging.eonline.com';
export const APP_VERSION = process.env.npm_package_version || process.env.APP_VERSION;
export const NBCU_CHECKOUT_URL =
	process.env.NBCU_CHECKOUT_URL ||
	'https://nonprod-commerce.nbcuni.com/uat/widget/latest/bootstrap.js';
export const FEATURE_TOGGLE_URL =
	process.env.FEATURE_TOGGLE_URL ||
	'https://s3-us-west-2.amazonaws.com/features.prod.eol/eol-web.json';
export const IDENTITY_SDK =
	process.env.IDENTITY_SDK || 'https://id.nbc.com/websdk/v2/identity-sdk.min.js';
