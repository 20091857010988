import React, { useState, useRef, useEffect } from 'react';
import { usePageContext } from '@/components/layout/page/PageContext';
import './IdentityModule.scss';

const IdentityModule = () => {
	const [dropdown, setDropdown] = useState(false);
	const { identityAuthentication } = usePageContext();
	const identityModuleInner = useRef();
	const identityModuleButton = useRef();

	const handleClickOutside = (e) => {
		if (
			identityModuleInner.current.contains(e.target) ||
			identityModuleButton.current.contains(e.target)
		)
			return;

		setDropdown(false);
	};

	const login = () => {
		if (typeof window !== 'undefined' && identityAuthentication) {
			window.nbc.identity.launch({
				referringPage: 'nav-login',
				registrationReferrer: 'None',
				defaultPage: 'createProfile'
			});
		}
	};

	const signOut = () => {
		if (typeof window !== 'undefined' && identityAuthentication)
			window.nbc.identity.unauthenticate();
	};

	useEffect(() => {
		if (dropdown) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdown]);

	return (
		<div className={`identity-module${dropdown ? ' login-open' : ''}`}>
			{identityAuthentication === 'authenticated' ? (
				<>
					<button
						className={`identity-module__logged-in${
							dropdown ? ' identity-module__logged-in--hover-state' : ''
						}`}
						onClick={() => {
							setDropdown(!dropdown);
						}}
						ref={identityModuleButton}
					></button>
					<div ref={identityModuleInner} className="identity-module__dropdown">
						<div className="identity-module__inner">
							<a className="identity-module__option" href="/news/e_insider">
								MY E! INSIDER
							</a>
							<a
								className="identity-module__option"
								href="https://id.eonline.com/my-account?brand=e"
							>
								MANAGE PROFILE
							</a>
							<a
								className="identity-module__option"
								href="https://id.eonline.com/email-preferences?brand=einsider"
							>
								EMAIL PREFERENCES
							</a>
							<button className="identity-module__option" onClick={signOut}>
								SIGN OUT
							</button>
						</div>
					</div>
				</>
			) : (
				<button onClick={login} className="identity-module__logged-out"></button>
			)}
		</div>
	);
};

IdentityModule.displayName = 'IdentityModule';

export default IdentityModule;
