import React from 'react';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { usePageContext } from '@/components/layout/page/PageContext';
import IdentityModule from './identity-module/IdentityModule';
import './NavBarIcons.scss';

/**
 * @function NavBarIcons
 * @description provides clickable icons for the NavBar
 * @returns {React.ReactElement}
 */
const NavBarIcons = () => {
	const { renderedMenu, toggleShowMenu, toggleShowSearch, updateRenderedMenu } = useNavContext();
	const { intlLinks } = usePageContext();
	const universalLink = intlLinks.UNIVERSAL_PLUS;
	const { IDENTITY_ENABLED_PARAM } = useParamToggleContext();
	const { edition } = usePageContext();

	return (
		<div className="nav-bar-icons">
			{universalLink && (
				<a
					className="nav-bar-icons__universal"
					data-analytics-linklocation="navigation"
					data-analytics-linkname="universal-plus-logo"
					href={universalLink}
					rel="noopener noreferrer"
					target="_blank"
				>
					{/* Universal+ icon */}
					<svg
						className="nav-bar-icons__universal-svg"
						id="UNI_"
						data-name="UNI+"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 918.03 500"
					>
						<path
							fill="currentColor"
							d="M490.72 217.77v-27.05h-84.09V306.1h84.88v-27.05h-54.9v-18.57h49.06v-25.2h-49.06v-17.51h54.11z"
						/>
						<path
							fill="currentColor"
							d="M7054.54 7141l-32.36.53 42.7 114.06H7093l41.38-115.38c-10.88 0-21.49.26-32.09.26l-22.55 72.94zM7198.57 6970.19c98.41 0 184.09 64.45 217 152h11.93c-32.35-100-126-172.68-237.13-172.68-108 0-200.27 69-234.75 165.51l36.87-.79c33.67-86.23 110.86-144.04 206.08-144.04zM7344.46 7180c0-24.93-17.51-39.78-44.56-39.78h-48.81v115.38h29.44v-35.28h14.86l18.61 35.28h33.95l-24.95-40.32c13-6.1 21.46-17.51 21.46-35.28zm-45.36 15.12h-18.57V7167h18.57c10.08 0 16.18 5 16.18 14.32 0 8.23-5.83 13.8-16.18 13.8zM6979.21 7255.6h29.71v-113.8c-9.82.27-19.9.54-29.71.54z"
							transform="translate(-6740.48 -6949.5)"
						/>
						<path
							fill="currentColor"
							d="M868.43 280.11v-81.17l-29.17-1.33V306.1h78.77v-25.47l-49.6-.52z"
						/>
						<path
							fill="currentColor"
							d="M6808.92 7208.38c0 15.12-7.7 22.29-19.37 22.29-11.94 0-19.36-6.9-19.36-22.29v-59.15c-9.81.53-19.89 1.06-29.71 1.33v58.88c0 32.9 18.83 48 48.81 48 29.71 0 49.07-15.39 49.07-50.4v-60.48l-29.44 1.06zM7494.59 7143.66l-44.83 111.94h30.51l7.69-19.89h40.32l7.69 19.89h31.3l-44.56-111.14zm1.86 67.91l11.93-31.3 11.68 31.57zM7406 7184.78c-14.59-3.72-18.3-7.43-18.3-11.41 0-4.24 3.44-7.16 10.61-7.16 9.28 0 19.62 4 29.44 11.4l14.32-21.75c-11.4-10.07-25.2-15.91-43-15.91-25.2 0-40.84 14.59-40.84 36.08 0 20.42 17.24 30.5 39.78 36.33 14.06 3.72 17.51 6.1 17.51 11.14s-4.24 7.7-11.94 7.7c-12.2 0-23.61-4.78-33.95-13.8l-16.18 21c12.73 12.73 30.5 19.1 49.07 19.1 25.47 0 42.44-13.8 42.44-36.61.04-21.53-14.82-30.01-38.96-36.11z"
							transform="translate(-6740.48 -6949.5)"
						/>
						<path
							fill="currentColor"
							d="M216.97 193.36l-28.91.8v59.16l-43.23-57.83-27.06.8V306.1h28.65v-60.74l44.82 60.74h25.73V193.36z"
						/>
						<path
							fill="currentColor"
							d="M7346.64 7381.55c-39.07 33.27-89.75 53.36-149.4 53.36-107.69 0-185.14-62.33-217-150.4h-22.81c31.83 97.35 123.34 165 232.1 165 68.91 0 129.9-25.22 173.65-67.95zM7386.76 7337.92h-32v-24.69h32V7281h25.1v32.2h32v24.69h-32v32.2h-25.1z"
							transform="translate(-6740.48 -6949.5)"
						/>
					</svg>
				</a>
			)}
			<label htmlFor="queryly_toggle" className="queryly_toggle">
				<div
					className="nav-bar-icons__search nav-bar-icons__icon queryly_search_button"
					data-analytics-linklocation="navigation"
					data-analytics-linkname="search"
					onClick={toggleShowSearch}
					onKeyPress={toggleShowSearch}
					role="button"
					tabIndex="0"
				>
					{/* Search icon */}
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="white"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_742_38815)">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M9.01 18.02c1.84 0 3.54-.55 4.97-1.49L21.45 24 24 21.45l-7.47-7.47a8.977 8.977 0 0 0 1.49-4.97C18.02 4.03 13.99 0 9.01 0S0 4.03 0 9.01s4.03 9.01 9.01 9.01Zm6.22-9.01c0 3.43-2.78 6.22-6.22 6.22s-6.22-2.78-6.22-6.22a6.22 6.22 0 0 1 12.44 0Z"
							/>
						</g>
						<defs>
							<clipPath id="clip0_742_38815">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</div>
			</label>
			{IDENTITY_ENABLED_PARAM && edition === 'us' && <IdentityModule />}
			<button
				className="nav-bar-icons__icon nav-bar-icons__hamburger"
				data-analytics-linklocation="navigation"
				data-analytics-linkname="hamburger"
				data-hook="nav-bar__menu-button"
				data-testid="nav-bar-hamburger"
				onClick={() => {
					if (!renderedMenu) updateRenderedMenu(true);
					toggleShowMenu();
				}}
				onKeyPress={toggleShowMenu}
				aria-label="menu"
				aria-expanded="false"
				tabIndex="0"
			>
				<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M24 4H0V2H24V4Z" />
					<path fillRule="evenodd" clipRule="evenodd" d="M24 13H0V11H24V13Z" />
					<path fillRule="evenodd" clipRule="evenodd" d="M24 22H0V20H24V22Z" />
				</svg>
			</button>
		</div>
	);
};

NavBarIcons.displayName = 'NavBarIcons';

export default NavBarIcons;
